@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("/fonts/MaterialIcons-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Condensed Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed Light"), local("RobotoCondensed-Light"),
    url("/fonts/roboto-condensed-v16-latin-300.woff2") format("woff2"),
    url("/fonts/roboto-condensed-v16-latin-300.woff") format("woff");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"),
    url("/fonts/roboto-condensed-v16-latin-regular.woff2") format("woff2"),
    url("/fonts/roboto-condensed-v16-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/fonts/roboto-v18-latin-300.woff2") format("woff2"),
    url("/fonts/roboto-v18-latin-300.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url("/fonts/roboto-v18-latin-regular.woff2") format("woff2"),
    url("/fonts/roboto-v18-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/fonts/roboto-v18-latin-500.woff2") format("woff2"),
    url("/fonts/roboto-v18-latin-500.woff") format("woff");
}

html {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul {
  margin: 0;
  padding: 0;
}

